import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Loops in Python Explained",
  "author": "tzookb",
  "type": "post",
  "date": "2020-08-04T13:50:08.000Z",
  "url": "/loops-in-python-explained",
  "featuredImage": "./roller.jpg",
  "categories": ["python", "loops"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Loop with index`}</h2>
    <p>{`The way we do it here is using the indexes and getting the item with the index each time.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [1,3,5,7,9]
sum_squared = 0

for i in range(len(x)):
    sum_squared+=x[i]**2
`}</code></pre>
    <h2>{`Loop with actual value`}</h2>
    <p>{`here we skip the index, of course this works only in the case we don't need the index.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [1,3,5,7,9]
sum_squared = 0

for y in x:
    sum_squared+=y**2
`}</code></pre>
    <h2>{`One Liner`}</h2>
    <p>{`Here we loop over the array and in the same line power the value`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [1,3,5,7,9]
sum_squared = sum([y**2 for y in x])
`}</code></pre>
    <h2>{`Inline Loop with if`}</h2>
    <p>{`Same as above, but with small if to filter the values.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [1,2,3,4,5,6,7,8,9]
even_squared = [y**2 for y in x if y%2==0]
--------------------------------------------
[4,16,36,64]
`}</code></pre>
    <h2>{`Inline Loop with if/else`}</h2>
    <p>{`Now we use if/else in case we want to handle the mapping in a different way by the value.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [1,2,3,4,5,6,7,8,9]
squared_cubed = [y**2 if y%2==0 else y**3 for y in x]
--------------------------------------------
[1, 4, 27, 16, 125, 36, 343, 64, 729]
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      